import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import ProjectPageWrapper from "../../components/project_page/ProjectPageWrapper"
import TextBlock from "../../components/project_page/TextBlock"
import Mobile3 from "../../components/project_page/Mobile3"
import Web1 from "../../components/project_page/Web1"
import Image1 from "../../components/project_page/Image1"
import ImageGrid from "../../components/project_page/ImageGrid"
import YoutubeWrap from "../../components/project_page/image_wrappers/YoutubeWrap"

import globalStyles from "../global.module.css"

const headerBackground = "#DEE7FF"
const navColor = "#222"
const Wedping = ({ data, location }) => (
  <Layout headerBackground={headerBackground} navColor={navColor}>
    <ProjectPageWrapper
      fromList={location && location.state && location.state.fromList}
      title="Wedping"
      oneLiner="The easiest way to text your wedding guests"
      link="https://www.wedping.com"
      image={<Img fluid={data.header.childImageSharp.fluid} />}
      mobileImage={<Img fluid={data.mobileHeader.childImageSharp.fluid} />}
      headerBackground={headerBackground}
    >
      <div className={`${globalStyles.marginBottomNormal}`}>
        <TextBlock
          title="Coming soon"
          headline={`Launched February 2024`}
          content={`Project details coming soon`}
        />
      </div>
    </ProjectPageWrapper>
  </Layout>
)

export default Wedping

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "projects/wedping/header.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeader: file(
      relativePath: { eq: "projects/wedping/header-mobile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
